.btn {
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-size: $h7-font-size;
  letter-spacing: 1.5px;
  padding: 0 20px 3px;
  border: 0;
  border-bottom: 1px solid $green;
  border-radius: 0;
  transition: all .3s ease;
  &:hover {
    text-decoration: none;
    padding: 0 40px 3px;
    transition: all .3s ease;
  }
  .bg-dark & {
    color: $lightgrey;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1.2em;
  height: 1.2em;
  padding: .25em .25em;
  margin-bottom: .5em;
  color: $primary;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232b2e34'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: 1;
  .offcanvas-header & {
    margin-bottom: -.25rem;
  }
}

.btn-menu {
  position: absolute;
  top: 2rem;
  right: 3rem;
}

.page-link {
  &:hover {
    color: inherit ;
    background-color: inherit;
    border-color: $green;
    padding: 0 20px 3px;
  }
}

.hamburger {
  background: transparent;
  color: transparent !important;
  border-color: transparent !important;
  border: 0;

  .linie {
    transition: all .3s ease-in-out;
  }

  .linie-0 {
    transform: translate(575px, 2581px);
  }
  .linie-1 {
    transform: translate(575px, 2590px);
  }
  .linie-2 {
    transform: translate(560px, 2599px);
  }

  &:hover {
    .linie {
      stroke: $green;
    }

    .linie-1 {
      transform: translate(560px, 2590px);
    }
    .linie-2 {
      transform: translate(575px, 2599px);
    }
  }
}

.btn-menu {
  border: 0;
  background-color: transparent;
  position: relative;
  max-height: 1rem;
  font-size: $h7-font-size;
  padding: 0;
  display: flex;
  margin: auto 0;

  .title {
    display: block;
    margin-right: .5rem;
  }

  .burger {
    display: block;
    width: 1.5rem;
    border-top: 2px solid $dark;
    border-bottom: 2px solid $dark;

    &::after {
      content: '';
      display: block;
      width: 1rem;
      border-top: 2px solid $dark;
      margin-top: 0.32rem;
    }
  }
}
