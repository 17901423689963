.section-news {
  border: 1rem solid $green;
  text-align: center;

  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.extra {
  margin-bottom: $spacer * 2;
  color: $dark;
  font-size: $h7-font-size;
}

@include media-breakpoint-up(md) {
  .section-news {
    border: 2rem solid $green;
  }
}
@include media-breakpoint-up(lg) {
  .section-news {
    border: 3rem solid $green;
  }
}
