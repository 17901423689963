/*
  t3_template_nt.scss
*/

// custom bootstrap scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here
// (though functions won't be available)

// @import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

// custom bootstrap scss
// Option B: Include parts of Bootstrap
// The following order originates from documentation v5.1.x
// and node_modules/bootstrap/scss/bootstrap.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here and/or add your own variables.
// Copy variables from `_variables.scss` to here to override default values
// without modifying source files.

$green:       rgba(47, 255, 3, 1) !default;

$palegrey:    rgba(246, 247, 249,1) !default;
$lightgrey:   rgba(247, 248, 250, 1) !default
$midgrey:     rgba(159, 165, 178, 1) !default;
$darkgrey:    rgba(43, 46, 52, 1) !default;

$white:       rgb(255, 255, 255) !default;
$black:       rgb(000, 000, 000) !default;

$primary:     $darkgrey !default;
$secondary:   $green !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6
) !default;

$enable-negative-margins:     true !default;

$body-color:                  $darkgrey !default;

$link-color:                  $primary !default;

$paragraph-margin-bottom:     $spacer * 1 !default;

$btn-focus-width:             0 !default;
$btn-focus-box-shadow:        none !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;

$font-family-sans-serif: "Josefin Sans", system-ui, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base:              1rem * 1.25 !default; // here 20px not 16px
$h1-font-size:                $font-size-base * 2.4 !default; // 48px
$h2-font-size:                $font-size-base * 2 !default; // 40px
$h3-font-size:                $font-size-base * 1.6 !default; // 32px
$h4-font-size:                $font-size-base * 1.4 !default; // 28px
$h5-font-size:                $font-size-base * 1.2 !default; // 24px
$h6-font-size:                $font-size-base * 1 !default; // 20px
$h7-font-size:                $font-size-base / 1.25 !default; // 16px

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size
) !default;

$headings-margin-bottom:      $spacer * .5 !default;
$headings-line-height:        1.25 !default;

$hr-margin-y:                 $spacer * 2 !default;

$card-bg:                     transparent !default;
$card-border-color:           transparent !default;
$card-cap-bg:                 transparent !default;

$alert-padding-y:             $spacer / 2 !default;
$alert-padding-x:             $spacer / 2 !default;
$alert-margin-bottom:         0 !default;
$alert-border-radius:         0 !default;

$alert-bg-scale:              0 !default;
$alert-color-scale:           100% !default;

$offcanvas-horizontal-width:  50vw !default;

$code-color:                  $darkgrey !default;

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";

@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
// @import "tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
// @import "button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
// @import "breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
// @import "badge";
@import "../node_modules/bootstrap/scss/alert";
// @import "progress";
@import "../node_modules/bootstrap/scss/list-group";
// @import "close";
// @import "toasts";
// @import "modal";
// @import "tooltip";
// @import "popover";
@import "../node_modules/bootstrap/scss/carousel";
// @import "spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
// @import "placeholders";

@import "../node_modules/bootstrap/scss/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

@import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

/*
  Custom
*/

@import "./fonts.scss";
@import "./home.scss";
@import "./buttons.scss";
@import "./news.scss";
@import "./cards.scss";
@import "./accordion.scss";

/*
  Elements
*/

html {
}
body {
  font-weight: 300;
  letter-spacing: .005rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-x: hidden;
  hyphenate-limit-chars: 10 4 4;
  hyphenate-limit-lines: 2;
}

a {
  color: currentColor;
  &:hover {
    color: currentColor;
  }
}
a {
  &:hover {
    text-decoration: underline;
  }
}

p {
  margin-bottom: 1.2rem;
}

.ce-bodytext {
  p:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  // padding-left: 1rem;
  list-style-position: inside;
  li::marker {
    font-size: .875rem;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  hyphens: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  color: $dark;
  font-weight: 500;
}
strong,
b {
}

h1,
.h1 {
  hyphens: none;
}
h2.h1 {
  @include media-breakpoint-down(md) {
    hyphens: auto;
  }
}
h2,
.h2 {
}
h3,
.h3 {
}
h4,
.h4 {
}
h5,
.h5 {
}
h6,
.h6 {
}

.ratio-4x3 {
  @include media-breakpoint-up(sm) {
    --bs-aspect-ratio: calc(9 / 21 * 100%);
  }
}

/*
  new IDs and Classes
*/

.bg-primary {
  position: relative;
  color: $white;
  background-color: $primary;
  &::after {
    position: absolute;
    top: 0;
    right: 50%;
    left: 50%;
    z-index: -1;
    width: 100%;
    width: 100vw;
    height: 100%;
    margin-right: -50vw;
    margin-left: -50vw;
    content: "";
    background-color: inherit;
  }
}
.text-primary {
  color: $primary !important;
}
.bg-secondary {
  position: relative;
  color: $white;
  background-color: $secondary;
  &::after {
    position: absolute;
    top: 0;
    right: 50%;
    left: 50%;
    z-index: -1;
    width: 100%;
    width: 100vw;
    height: 100%;
    margin-right: -50vw;
    margin-left: -50vw;
    content: "";
    background-color: inherit;
  }
}
.text-secondary {
  color: $secondary;
}

.bg-white {
  background-color: $white;
}

.bg-palegrey {
  background-color: $palegrey;
}

.text-midgrey {
  color: $midgrey;
}

.bg-image {
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  color: $white;
}

.h-lg-100 {
  height: 100%;
}

.responsive-video {
  position: relative;
  height: 0;
  padding-top: 15px;
  padding-bottom: 55%;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.videobild {
  position: relative;
  .texte {
    position: absolute;
    top: 4rem;
    left: 4rem;
    max-width: 555px;
    background: $white;
    background: rgba($white, .6);
  }
}

.frame-indent {
  margin-left: 0 !important;
  margin-right: 0 !important;
  @include media-breakpoint-up(md) {
    margin-left: 15% !important;
    margin-right: 15% !important;
  }
}
.youtubevideo {
  .youtubevideo-gdpr-overlay {
    background: rgba(0, 0, 0, 0.8) !important;
    color: $white !important;
    font-weight: 300 !important;
    a {
      text-decoration: none;
      color: $green;
      &.youtubevideo-gdpr-button {
        color: $primary;
        &:hover {
          color: $green;
        }
      }
    }
    .youtubevideo-gdpr-text {
      max-width: none;
    }
  }
}

.navbar {
  .navbar-brand {
    padding: 0;
    img {
      width: 60px;
      height: 60px;
    }
  }
}

.offcanvas {
  width: 100vw;
  @include media-breakpoint-up(md) {
    width: 70vw;
  }
  @include media-breakpoint-up(lg) {
    width: 60vw;
  }
  @include media-breakpoint-up(xl) {
    width: 50vw;
  }
  background-color: $lightgrey;

  .dropstart {
    overflow-x: hidden;
    hyphens: auto;
    .nav-link {
      padding: .5rem 0;
    }
    .dropdown-menu {
      position: static !important;
      inset: 0 !important;
      margin: auto !important;
      transform: none !important;
      background-color: $lightgrey;
      border: 0;
      padding: .5rem 0 1rem 1rem;
      @include media-breakpoint-up(md) {
        padding: .5rem 0 1rem 2.5rem;
      }
      .dropdown-item {
        width: auto;
        white-space: normal;
        font-weight: 300;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .dropdown-toggle {
      &::before {
        display: inline-block;
        margin-right: .255em;
        content: "＋";
        border: 0;
        transition: all .1s ease;
      }
      &.show {
        &::before {
          transform: rotate(135deg);
          transform-origin: 50% 42%;
          transition: all .1s ease;
        }
      }
      &:focus-visible,
      &:focus {
        outline: 0;
      }
    }
    .active {
      font-weight: bolder;
    }
    .current {
      text-decoration: underline;
    }
  }

  .btn-close-me {
    border: 0;
    padding: 0;
    &:hover {
      padding: 0;
      border: 0;
    }
    &::after {
      content: "＋";
      font-weight: 300;
      display: inline-block;
      transform: rotate(45deg);
    }
  }
}

.one-cols h2:not(.no-marker),
.two-cols h2:not(.no-marker),
.news h2,
.marker {
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 0;
  padding-top: 2.5rem;
  &:not(.low-marker) {
    @include media-breakpoint-up(md) {
      padding-top: calc(2.5rem + 50px);
    }
  }
  &::before {
    content: '';
    position: absolute;
    height: 2rem;
    width: 1px;
    background: $green;
    top: -2.5px;
    left: 50%;
  }
  &:not(.low-marker)::before {
    @include media-breakpoint-up(md) {
      top: calc(-2.5px + 50px);
    }
  }
  span {
    padding-bottom: 2rem;
    &.fs-6 {
      @include media-breakpoint-down(md) {
        font-size: .6em !important;
      }
    }
  }
  &.marker-large {
    &::before {
      top: -1.5rem;
      height: 3.5rem;
    }
  }
  &.marker-small {
    padding-top: 1rem;
    padding-bottom: 1rem;
    &::before {
      top: -.75rem;
      height: 1.5rem;
    }
  }
}

.marker-span {
  display: flex;
  flex-flow: row wrap;
  @include media-breakpoint-down(sm) {
    flex-flow: column wrap;
  }
  justify-content: center;
  span {
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    padding-top: 1.5rem;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 0;
    margin-left: 1rem;
    &::before {
      content: '';
      position: absolute;
      height: 1rem;
      width: 1px;
      background: $green;
      top: -1.5px;
      left: 50%;
    }
  }
  a.btn {
    margin: 1rem;
    font-weight: bold;
    &.collapsed {
      font-weight: normal;
    }
    &:hover {
      color: inherit ;
      background-color: inherit;
      border-color: $green;
      padding: 0 20px 3px;
    }
  }
}

.alert {
  &.alert-nt {
    text-align: center;
    font-size: $h7-font-size;
    p {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .w-small > * {
    max-width: 85%;
    margin-inline: auto;
  }
}

@include media-breakpoint-up(lg) {
  .w-small > * {
    max-width: 65%;
  }
}

.section--hero {
  line-height: 1.3;
  font-weight: 300;
  p {
    margin-bottom: .3rem;
  }
}

.tx-powermail {
  text-align: left;
  .form-control {
    font-weight: 300;
    border-radius: 0;
    border-color: $midgrey;
  }

  .form-label {
    padding-top: 2rem;
  }

}

footer {
  font-size: $h7-font-size;
  img {
    max-width: 225px;
  }
  a {
    text-decoration: none;
  }
}
footer {
  a {
    &[href^="http"] {
      background: url(/typo3conf/ext/t3_template_nt/Resources/Public/Icons/link_external.svg) no-repeat right center;
      padding-right: 1.25rem;
      background-size: 15px 15px;
    }
    &[href$=".pdf"] {
      background: url(/typo3conf/ext/t3_template_nt/Resources/Public/Icons/link_download.svg) no-repeat right center;
      padding-right: 1.25rem;
      background-size: 15px 15px;
    }
  }
}
.offcanvas {
  a {
    &[href^="http"] {
      background: url(/typo3conf/ext/t3_template_nt/Resources/Public/Icons/link_external_dark.svg) no-repeat right center;
      padding-right: 1.25rem;
      background-size: 25px 25px;
    }
    &[href$=".pdf"] {
      background: url(/typo3conf/ext/t3_template_nt/Resources/Public/Icons/link_download_dark.svg) no-repeat right center;
      padding-right: 1.25rem;
      background-size: 25px 25px;
    }
  }
}

section {
  a {
    &[href^="http"] {
      background: url(/typo3conf/ext/t3_template_nt/Resources/Public/Icons/link_external_dark.svg) no-repeat right 85%;
      padding-right: 1.25rem;
      background-size: 15px 15px;
    }
    &[href$=".pdf"] span {
      background: url(/typo3conf/ext/t3_template_nt/Resources/Public/Icons/link_download_dark.svg) no-repeat right;
      display: inline;
      padding-right: 1.75rem;
      background-size: 15px 15px;
    }
  }
}

.back-to-top {
  z-index: 2;
  position: fixed;
  display: none;
  bottom: 2rem;
  right: 1rem;
  width:36px;
  height: 20px;
  opacity: .6;
}

/*
  additional Mediaqueries
*/

// ≥576px
@include media-breakpoint-up(sm) {
}
// ≥768px
@include media-breakpoint-up(md) {
}
// ≥992px
@include media-breakpoint-up(lg) {
}
// ≥1200px
@include media-breakpoint-up(xl) {
}

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-only(xl) {
}

// <576px
@include media-breakpoint-down(xs) {
}
// <768px
@include media-breakpoint-down(sm) {
}
// <992
@include media-breakpoint-down(md) {
}
// <1200
@include media-breakpoint-down(lg) {
}

@media print {
}
