.logo {
  max-width: 105px;
  margin-bottom: 2rem;
  @include media-breakpoint-up(sm) {
    max-width: 152px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 210px;
  }
}

// header section
.section--hero-img {
  height: 100vh;
  max-height: 980px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-repeat:no-repeat;
  background-size: cover;
  background-position:center 35%;
  @include media-breakpoint-up(lg) {
    background-size: auto;
  }

  .marker {
    padding-bottom: 2.5rem;
  }

  .hamburger {
    position: absolute;
    top: .7rem;
    right: .9rem;
    @include media-breakpoint-up(lg) {
      top: 2rem;
      right: 4.5rem;
    }
  }

  .marker::after {
    content: '';
    position: absolute;
    height: 2rem;
    width: 1px;
    background: $green;
    bottom: -2.5px;
    left: 50%;
  }

  & .marker span {
    padding-bottom: 0;
  }

  h1 span:not(:last-child) {
    font-size: clamp($h2-font-size, 10vw, $h2-font-size * 2);
    line-height: 1;
    font-weight: 700;
  }
  h1 span:first-child {
    font-weight: 400;
  }
}

.alert + .section--hero-img {
  height: calc(100vh - 3rem);
}

@media (max-height: 640px) {
  .section--hero-img {
    max-height: 980px;
    flex-direction: column;
    justify-content: center;
  }
}

// headlines
.bg-dark {
  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }
}

// fullwidth 2 columns width image
.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-origin: border-box;
}

.section--teaser {
  overflow: hidden;
  @include media-breakpoint-up(md) {
    overflow: auto;
  }
}

.imgholder {
  aspect-ratio: 14 / 6;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-origin: border-box;
  position:relative;
  width: 130vw;
  left: calc(-1 * (130vw - 100%)/2);
}

@include media-breakpoint-up(md) {
  .imgholder {
    width: 100vw;
    left: calc(-1 * (100vw - 100%)/2);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-origin: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    z-index: 0;
    width: 50vw;
    height: 100%
  }

  .bg-image--left {
    position: relative;

    .col-12 {
      z-index: 1;
    }

    .imgholder  {
      left: 0;
    }
  }

  .bg-image--right {
    position: relative;

    .col-12 {
      z-index: 1;
    }

    .imgholder  {
      right: 0;
    }
  }
}

// other sections
.section--hero {
  &-home,
  &-inner {
    background-repeat: no-repeat;
    background-image: none;
    @include media-breakpoint-up(md) {
      background-image: url(/typo3conf/ext/t3_template_nt/Resources/Public/Images/NG_Logo_wortmarke_positiv.svg);
    }
  }
  &-home {
    background-size: 100px;
    background-position: bottom -5px left 1rem;
    @include media-breakpoint-up(lg) {
      background-size: 200px;
      background-position: bottom -35px left 1rem;
    }
  }
  &-inner {
    background-size: 150px;
    background-position: top 0px left 1rem;
    @include media-breakpoint-up(lg) {
      background-size: 100px;
      background-position: top 0px left 1rem;
    }
  }
}
