summary {
  border-bottom: 1px solid $dark;
  list-style: none;
  position: relative;
  padding-block: $spacer;
  strong {
    display: block;
  }
}

.s-body {
  max-width: 80%;
  margin: $spacer auto;
}

summary::-webkit-details-marker {display: none; }
details summary::after {
  content: "＋";
  font-size: $h3-font-size;
  line-height: 1;
  position: absolute;
  right: 0;
}
/* By using [open] we can define different styles when the disclosure widget is open */
details[open] summary::after {
  content: "—";
  right: 5px;
  line-height: .8;
}
